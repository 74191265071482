<template>
  <CBox>
    <CModal :isOpen="isModalOpen" :on-close="close">
      <CModalOverlay />
      <CModalContent
        mx="2"
        mt="-40px"
        style="display: flex; align-items: center; justify-content: center"
      >
        <CModalHeader
          >Invite Devcoder
          <CText fontSize="sm" fontWeight="400" d="flex">{{
            title ? "To " + title : ""
          }}</CText></CModalHeader
        >
        <CModalCloseButton />
        <CModalBody>
          <CFormControl id="inviteEmail">
            <CFormLabel for="email">Email</CFormLabel>
            <CTextarea
              id="email"
              v-model="params.data.emails"
              :placeholder="'john@gmail.com\nbecker@gmail.com\n...'"
            />
          </CFormControl>
        </CModalBody>
        <CModalFooter>
          <CButton
            colorScheme="blue"
            @click="postData"
            bg="bgButtonClub"
            color="white"
            :_hover="{ opacity: 0.8 }"
            :disabled="!params.data.emails"
            >Send Invite</CButton
          >
        </CModalFooter>
      </CModalContent>
    </CModal>
  </CBox>
</template>

<script>
import {
  CBox,
  CButton,
  CModalOverlay,
  CModalContent,
  CModalHeader,
  CModalCloseButton,
  CModalBody,
  CModalFooter,
  CFormControl,
  CFormLabel,
  CTextarea,
} from "@chakra-ui/vue";
import Swal from "sweetalert2";
import { POST_CLUB_INVITE } from "@/store/club.module";

export default {
  name: "InviteClub",
  components: {
    CBox,
    CButton,
    CModalOverlay,
    CModalContent,
    CModalHeader,
    CModalCloseButton,
    CModalBody,
    CModalFooter,
    CFormControl,
    CFormLabel,
    CTextarea,
  },
  props: {
    slug: String,
    title: String,
    isModalOpen: Boolean,
  },
  data() {
    return {
      params: {
        slug: this.slug,
        data: {
          emails: "",
        },
      },
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
  methods: {
    close() {
      this.$emit("closeModal");
    },
    async postData() {
      try {
        if (
          this.currentUser &&
          this.params.data.emails.match(
            /^[^\s;]+@[^\s;]+\.[^\s;]+(?:;[^\s;]+@[^\s;]+\.[^\s;]+)*$/
          )
        ) {
          this.params.slug = this.slug;
          await this.$store.dispatch(POST_CLUB_INVITE, this.params);
          this.params.data.emails = "";
          this.$emit("closeModal");
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "success",
            title: "Invitation sent email successfully",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Incorrect email writing format.",
          });
        }
      } catch (err) {
        console.log(err.response);
        this.messageFailed = err.response.data.message;
      }
    },
    generateInviteUrl() {
      return `${window.location.origin}/invite?code=uniqueCode`;
    },
    sendInvite() {
      this.$emit("sendInvite");
    },
  },
};
</script>

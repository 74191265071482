<template>
  <CBox>
    <CBox
      :class="{
        'd-none': isLoading,
        'croppie-modal': isModalOpen,
        'alert-content-close-active': isModalOpen == false,
      }"
      zIndex="1"
    >
      <CBox
        :class="{ 'alert-content-enter-active-slide-down': true }"
        position="absolute"
        zIndex="1"
        bg="white"
        py="4"
        rounded="md"
        shadow="lg"
        maxW="400px"
        w="100%"
      >
        <CText textAlign="center" my="2" fontSize="xl" fontWeight="semibold"
          >Preview Image</CText
        >
        <vue-croppie
          ref="croppieRef"
          :enableOrientation="true"
          :boundary="{ width: 300, height: 300 }"
          :original="true"
        ></vue-croppie>
        <CBox textAlign="right" px="8" mt="2">
          <CButton
            colorScheme="blue"
            @click="close"
            bg="red.400"
            color="white"
            :_hover="{ opacity: 0.8 }"
          >
            Cancel
          </CButton>
          <CButton
            colorScheme="blue"
            @click="rotate(90)"
            mx="2"
            bg="main"
            color="white"
            :_hover="{ opacity: 0.8 }"
          >
            Rotate
          </CButton>
          <CButton
            colorScheme="blue"
            @click="cropAndEmit()"
            bg="green.400"
            color="white"
            :_hover="{ opacity: 0.8 }"
          >
            Crop Image
          </CButton>
        </CBox>
      </CBox>
    </CBox>
  </CBox>
</template>

<script>
import { CBox } from "@chakra-ui/vue";
export default {
  name: "ImageCroppie",
  components: {
    CBox,
  },
  props: {
    urlImage: String,
    isModalOpen: Boolean,
  },
  watch: {
    urlImage(newVal) {
      if (newVal) {
        this.$refs.croppieRef.bind({
          url: this.urlImage,
        });
        this.isLoading = false;
      }
    },
  },
  data() {
    return {
      isLoading: true,
    };
  },
  methods: {
    bind() {
      let url = this.images[Math.floor(Math.random() * 4)];
      this.$refs.croppieRef.bind({
        url: url,
      });
    },
    cropAndEmit() {
      let options = {
        format: "png",
        circle: false,
        size: "original",
      };

      this.$refs.croppieRef.result(options, (output) => {
        const dataURL = output;
        this.$emit("cropCompleted", output);
        this.dataURLToBlob(dataURL).then((blob) => {
          const formData = new FormData();
          formData.append("file", blob, "profile_image.png");
          this.$emit("cropBlob", formData);
        });
        this.close();
      });
    },
    dataURLToBlob(dataURL) {
      return new Promise((resolve) => {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", dataURL);
        xhr.responseType = "blob";
        xhr.onload = () => {
          resolve(xhr.response);
        };
        xhr.send();
      });
    },
    rotate(rotationAngle) {
      this.$refs.croppieRef.rotate(rotationAngle);
    },
    close() {
      this.$emit("closeModal");
    },
  },
};
</script>

<template>
  <CBox
    boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
    borderRadius="5"
    w="100%"
    h="fit-content"
    my="5"
    bg="white"
    p="24px"
  >
    <InviteClub
      v-if="currentUser && !currentUser.urole_id && currentClub.is_joined === 1"
      :isModalOpen="isModalOpen"
      :slug="currentSlug"
      @closeModal="closeModal"
    />
    <CBox v-if="currentClub.is_joined !== 0" mb="4">
      <CText fontSize="18px" fontWeight="600"> Invite Devcoders </CText>
      <CButton
        bg="bgButtonClub"
        color="white"
        :_hover="{ opacity: '0.8' }"
        my="2"
        size="0"
        w="fit-content"
        px="16px"
        py="8px"
        mr="2"
        fontSize="12px"
        @click="openModal"
      >
        Invite Member
      </CButton>
    </CBox>
    <CBox mb="4">
      <CText fontSize="18px" fontWeight="600"> Share this Club </CText>
      <CButton
        :bg="!isUrlCopied ? 'white' : 'main'"
        :color="!isUrlCopied ? 'main' : 'white'"
        :border="!isUrlCopied ? '#29AAFE 1px solid' : ''"
        :_hover="!isUrlCopied ? { opacity: '0.8' } : { opacity: '1' }"
        my="2"
        size="0"
        w="fit-content"
        px="16px"
        py="8px"
        fontSize="12px"
        @click="copyUrl"
      >
        Share
      </CButton>
    </CBox>
    <CBox>
      <CText fontSize="16px" fontWeight="400"> Total members </CText>
      <CText fontSize="24px" fontWeight="600">
        {{ currentClub.total_member }}
      </CText>
    </CBox>
    <CText
      v-if="
        currentClub.is_joined !== 0 && currentClub.emp_id !== currentUser.emp_id
      "
      color="#E82609"
      :_hover="{ textDecoration: 'underline' }"
      cursor="pointer"
      fontSize="12px"
      rounded="md"
      d="flex"
      w="fit-content"
      mt="1"
      alignItems="center"
      fontWeight="600"
      @click.native="handleLeave"
    >
      Leave Club
      <CImage
        :src="require('@/assets/icon/icon-leave.svg')"
        ml="2px"
        w="12px"
        d="inline-block"
      />
    </CText>
    <CText
      v-if="!currentUser"
      color="main"
      :_hover="{ textDecoration: 'underline' }"
      cursor="pointer"
      fontSize="12px"
      rounded="md"
      d="flex"
      w="fit-content"
      mt="1"
      alignItems="center"
      fontWeight="600"
      @click.native="handleLogin"
    >
      Login
    </CText>
  </CBox>
</template>

<script>
import { CBox, CText } from "@chakra-ui/vue";
import { POST_CLUB_LEAVE_MEMBER } from "@/store/club.module";
import config from "@/ConfigProvider.js";
import InviteClub from "@/components/modal/InviteClub.vue";
import Swal from "sweetalert2";

export default {
  components: {
    InviteClub,
    CBox,
    CText,
  },
  data() {
    return {
      isLoading: false,
      isUrlCopied: false,
      isModalOpen: false,
      urlGethired: config.value("GETHIRED_WEB_URL"),
    };
  },
  methods: {
    handleLogin() {
      window.location.href =
        config.value("GETHIRED_WEB_URL") +
        `/signin?next=${window.location.href}`;
    },
    async handleLeave() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, leave!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            let params = {
              slug: this.$route.params.slug,
            };
            await this.$store.dispatch(POST_CLUB_LEAVE_MEMBER, params);
            Swal.fire({
              title: "Leaved club!",
              text: "You has been leaved the club.",
              icon: "success",
            }).then(() => {
              window.location.reload();
            });
          } catch (error) {
            console.error("Error leaving club:", error);
            Swal.fire({
              title: "Error",
              text: "An error occurred while leaving the club.",
              icon: "error",
            });
          }
        }
      });
    },
    copyUrl() {
      const inviteUrl = window.location;
      const dummyInput = document.createElement("input");
      document.body.appendChild(dummyInput);
      dummyInput.value = inviteUrl;
      dummyInput.select();
      document.execCommand("copy");
      document.body.removeChild(dummyInput);

      this.isUrlCopied = true;
      Swal.fire({
        title: "Copied",
        text: "Club " + this.currentClub.name + " url ready to share.",
        icon: "success",
      });
      setTimeout(() => {
        this.isUrlCopied = false;
      }, 2000);
    },
    openModal() {
      this.isModalOpen = true;
    },
    async closeModal() {
      this.isModalOpen = false;
    },
  },
  computed: {
    currentUser() {
      const user = this.$store.getters.currentUser;
      return user;
    },
    currentClub() {
      return this.$store.getters.currentClub || [];
    },
    currentSlug() {
      return this.$route.params.slug;
    },
  },
};
</script>
